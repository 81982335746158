import React from 'react';

import { Layout, SEO } from 'components';
import { Appointments } from 'containers';

const AppointmentsPage = () => (
  <Layout>
    <SEO title='Appointments' />
    <Appointments />
  </Layout>
);

export default AppointmentsPage;
